import React, { useState } from 'react';
import { Container, Form, Button, ListGroup } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from '@auth0/auth0-react';

const ToDoList = () =>{
  const { isAuthenticated } = useAuth0();
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState('');
   
  const handleInputChange = (event) => {
    setNewTask(event.target.value);
  };

  const handleAddTask = () => {
    if (newTask.trim() !== '') {
      const task = {
        id: uuidv4(),
        content: newTask,
      };

      setTasks([...tasks, task]);
      setNewTask('');
    }
  };

const handleDeleteTask = (id) => {
setTasks(tasks.filter((task) => task.id !== id));
};
  return (
      isAuthenticated && (
        <Container>
    <h1 className="mt-4">To-Do List</h1>
    <Form className="my-4">
      <Form.Group>
        <Form.Control
          type="text"
          placeholder="Enter task"
          value={newTask}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Button variant="primary" class="btn btn-primary addbutton" onClick={handleAddTask}>
        Add Task
      </Button>
    </Form>
    <ListGroup>
      {tasks.map((task) => (
        <ListGroup.Item key={task.id} className="d-flex justify-content-between">
          {task.content}
          <Button variant="danger" onClick={() => handleDeleteTask(task.id)}>
            Delete
          </Button>
        </ListGroup.Item>
      ))}
    </ListGroup>
  </Container>

      )
    ) 
}

export default ToDoList;
