import { useAuth0 } from '@auth0/auth0-react';

const Profile = () => {
    const { user, isAuthenticated } = useAuth0();
  
    return (
        isAuthenticated && (
            <article>
                {user?.picture && <img class="rounded-circle" src={user.picture} alt={user?.name} />}
                {user?.name}
                {/* <ul>
                    {Object.keys(user).map((objKey,i) => <li key={i}>{objKey}: {user[objKey]}</li>)}
                </ul> */}
            </article>
        )
    )
  }
export default Profile